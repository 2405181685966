"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MARGIN = exports.LENGTH = exports.SIZE = exports.controlAlphabet = exports.keys = void 0;
var keys;
(function (keys) {
    // RETURN = 13,
    keys[keys["SPACE"] = 32] = "SPACE";
    keys[keys["LEFT"] = 37] = "LEFT";
    keys[keys["UP"] = 38] = "UP";
    keys[keys["RIGHT"] = 39] = "RIGHT";
    keys[keys["DOWN"] = 40] = "DOWN";
    // C = 67,
    // G = 71,
    // J = 74,
    // K = 75
})(keys = exports.keys || (exports.keys = {}));
var controlAlphabet;
(function (controlAlphabet) {
    controlAlphabet[controlAlphabet["LEFT"] = 65] = "LEFT";
    controlAlphabet[controlAlphabet["UP"] = 87] = "UP";
    controlAlphabet[controlAlphabet["RIGHT"] = 68] = "RIGHT";
    controlAlphabet[controlAlphabet["DOWN"] = 83] = "DOWN";
})(controlAlphabet = exports.controlAlphabet || (exports.controlAlphabet = {}));
exports.SIZE = 20;
exports.LENGTH = 5;
exports.MARGIN = 0;
