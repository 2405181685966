"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Locations;
(function (Locations) {
    const data = new Map(); // { [location: string]: boolean } = {};
    Locations.set = (x, y, value = true) => {
        data.set(`${x}:${y}`, value);
    };
    Locations.remove = (x, y) => {
        data.delete(`${x}:${y}`);
    };
    Locations.has = (x, y) => {
        return data.has(`${x}:${y}`);
    };
    Locations.get = (x, y) => {
        return data.get(`${x}:${y}`);
    };
    Locations.getAll = () => {
        return data.values();
    };
    Locations.flush = () => {
        data.clear();
    };
})(Locations || (Locations = {}));
exports.default = Locations;
