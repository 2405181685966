"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
var Directions;
(function (Directions) {
    let queue = [];
    let current = constants_1.keys.RIGHT;
    Directions.set = (key) => {
        queue.push(key);
    };
    Directions.get = () => {
        return current;
    };
    Directions.pop = () => {
        if (queue.length > 0) {
            current = queue.shift();
        }
        return Directions.get();
    };
    Directions.flush = () => {
        queue = [];
    };
    Directions.peek = () => {
        return queue.length > 0 ? queue[queue.length - 1] : current;
    };
    Directions.setCurrent = (direction) => {
        switch (direction) {
            case 'RIGHT':
                current = constants_1.keys.RIGHT;
                break;
            case 'DOWN':
                current = constants_1.keys.DOWN;
                break;
            case 'UP':
                current = constants_1.keys.UP;
                break;
            case 'LEFT':
                current = constants_1.keys.LEFT;
                break;
        }
    };
    Directions.setCurrentByKey = (key) => {
        current = key;
    };
    // export const dump = (): number[] => {
    //     return queue
    // }
})(Directions || (Directions = {}));
exports.default = Directions;
