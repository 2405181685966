"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
var Utils;
(function (Utils) {
    Utils.rand = (min, max, reduce = constants_1.SIZE) => {
        const num = Math.floor(Math.random() * (max - min)) + min;
        return num - (num % reduce);
    };
    Utils.snap = (num, point = constants_1.SIZE) => {
        const bottom = num - (num % point);
        const top = bottom + point;
        return num - bottom <= top - num ? bottom : top;
    };
    Utils.removeNode = (el) => {
        if (el.parentNode) {
            el.parentNode.removeChild(el);
        }
    };
    Utils.bound = (num, min, max) => {
        return Math.max(Math.min(num, max), min);
    };
})(Utils || (Utils = {}));
exports.default = Utils;
